import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import React from 'react'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as Session from './session'
import * as Configuration from './configuration'
import { QueryDrawer } from './query-drawer'

const LoginBox = loadable(() => import('./login-box'))

export function LoginDrawer() {
  const location = ReactRouter.useLocation()
  const i18n = I18n.useI18n()
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const [returningPlayer] = Cookies.useCookie(
    Constants.CookieKeys.RETURNING_PLAYER
  )
  const countryCode = ReactRedux.useSelector(state =>
    Configuration.getCountryCode(state.configuration)
  )

  // Login is available on the landing-page as a returning user but not for Canada.
  if (
    authenticated ||
    (returningPlayer && location.pathname === '/' && countryCode != 'CA')
  ) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="login"
      title={i18n.translate('login-page.title')}
    >
      <Common.Box pb={6} color="drawer-text-color">
        <LoginBox />
      </Common.Box>
    </QueryDrawer>
  )
}

// for @loadable/components
export default LoginDrawer
